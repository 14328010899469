<template>
  <b-modal
    :id="id"
    size="md"
    title="Thêm tổ chức cung cấp dịch vụ"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateOverTime"
    @hidden="resetState"
    @show="fetchComboboxType"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="basicInput">Ngày làm thêm <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="dateOverTime"
              rules="required"
              :custom-messages="{required: `Ngày làm thêm là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataInput.dateOverTime"
                :disabledInput="true"
                :class="{'is-invalid':errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="basicInput">Số giờ làm thêm <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="hour"
              rules="required"
              :custom-messages="{required: `Số giờ làm thêm là bắt buộc`}"
            >
              <b-form-input
                v-model="dataInput.hour"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import DataFilter from '../../constants/DataFilter'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BFormCheckbox,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    VSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      dataFilterType: DataFilter.dataFilterType,
      customCode: {
        required: 'Hạn nộp là bắt buộc',
      },
      typeReport: {
        required: 'Chọn loại báo cáo là bắt buộc',
      },
      customSelectVali: {
        required: 'Loại báo cáo là bắt buộc',
      },
      dataInput: {
        dateOverTime: '',
        hour: 0,
      },
      dataCombobox: [],
    }
  },
  methods: {
    handleCreateOverTime(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateOverTime', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        dateOverTime: '',
        hour: 0,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
  .btn-button-file {
    width: 40%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
