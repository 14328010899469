<template>
  <div>
    <!-- ===================================== ô input nhập yêu cầu ==================================-->
    <div>
      <label for="textarea-default">Quá trình đào tạo</label>
      <b-form-textarea
        id="textarea-default"
        v-model="dataTraining.trainingProgress"
        placeholder=""
        rows="3"
        no-resize
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BFormTextarea, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BCol,
  },
  data() {
    return {
      dataTraining: {
        trainingProgress: '',
      },
    }
  },
  computed: {
    ...mapState('worker', ['dataEdit']),
  },
  watch: {
    dataEdit(val) {
      if (val) {
        this.dataTraining = {
          trainingProgress: this.dataEdit.trainingProgress,
        }
      }
    },
  },
}
</script>

<style lang="scss">
</style>
