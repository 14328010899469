<template>
  <b-modal
    :id="id"
    size="sm"
    title="Thêm quá trình làm việc"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleCreateProgress"
    @hidden="resetState"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="InputHelp">Nơi làm việc <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="locationWorking"
              rules="required"
              :custom-messages="customPlace"
            >
              <b-form-input
                id="InputHelp"
                v-model="modalCreateProgress.locationWorking"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="InputHelp">Địa điểm làm việc <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="addressWorking"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="InputHelp"
                v-model="modalCreateProgress.addressWorking"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>

      <b-form-group>
        <label for="InputHelp">Vị trí công việc</label>
        <b-form-input
          id="InputHelp"
          v-model="modalCreateProgress.position"
        />
      </b-form-group>

      <b-form-group>
        <label for="InputHelp">Chức danh công việc</label>
        <b-form-input
          id="InputHelp"
          v-model="modalCreateProgress.title"
        />
      </b-form-group>

      <b-form-group>
        <label>Ngày bắt đầu</label>
        <date-time-picker
          v-model="modalCreateProgress.dateStart"
          :disabledInput="true"
        />
      </b-form-group>

      <b-form-group>
        <label>Ngày kết thúc</label>
        <date-time-picker
          v-model="modalCreateProgress.dateEnd"
          :disabledInput="true"
        />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      required,
      customCode: {
        required: 'Địa điểm làm việc là bắt buộc',
      },
      customPlace: {
        required: 'Nơi làm việc là bắt buộc',
      },
      modalCreateProgress: {
        workerId: this.$route.params.id,
        locationWorking: '',
        addressWorking: '',
        position: '',
        title: '',
        dateStart: null,
        dateEnd: null,
      },
    }
  },
  methods: {
    handleCreateProgress(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$emit('handleCreateProgress', this.modalCreateProgress, this.$refs.rule)
    },
    resetState() {
      this.modalCreateProgress = {
        locationWorking: '',
        addressWorking: '',
        position: '',
        title: '',
        dateStart: null,
        dateEnd: null,
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
