<template>
  <div>
    <!--Phần header của bảng -->
    <button-all-header
      :contentBtnAdd="'Thêm quá trình làm việc'"
      :arrayExcel="[]"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      @clickDelete="deleteItems"
      @search="search($event)"
      @clickAdd="openModalCreateProgress"
    />
    <!--Phần Bảng -->
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataTable || []"
      style-class="vgt-table"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Chức năng -->
        <span v-if="props.column.field == 'cn'">
          <span>
            <feather-icon
              icon="Trash2Icon"
              size="18"
              style="margin-left: 14px"
              @click.stop="deleteItem(props.row.id)"
            />
          </span>
          <!-- Nơi làm việc -->
          <span v-if="props.column.field == 'locationWorking'">
            {{ props.row.locationWorking }}
          </span>
          <!-- Địa điểm làm việc -->
          <span v-else-if="props.column.field == 'addressWorking'">
            {{ props.row.addressWorking }}
          </span>
          <!-- Vị trí công việc -->
          <span v-else-if="props.column.field == 'position'">
            {{ props.row.position }}
          </span>
          <!-- Chức danh công việc -->
          <span v-else-if="props.column.field == 'title'">
            {{ props.row.title }}
          </span>
        </span>
        <!-- Ngày bắt đầu -->
        <span v-else-if="props.column.field == 'dateStart'">
          {{ props.row.dateStart | formatDateToDDMM }}
        </span>
        <!-- Ngày kết thúc -->
        <span v-else-if="props.column.field == 'dateEnd'">
          {{ props.row.dateEnd | formatDateToDDMM }}
        </span>
      </template>
    </vue-good-table>
    <my-pagination
      :totalItems="totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="handlePageClick"
    />

    <!-- modal xóa giấy phép-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />

    <!-- Tạo mới quá trình làm việc -->
    <modal-create-progress
      :id="modalCreateProgress"
      @handleCreateProgress="handleCreateProgress"
    />
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import ModalCreateWorkProgress from '../components/ModalCreateWorkProgress.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    'modal-create-progress': ModalCreateWorkProgress,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      modalCreateProgress: 'comfirm-progress',

      // ==dữ liệu bảng
      rows: [],
      columns: [
        {
          label: 'NƠI LÀM VIỆC',
          field: 'locationWorking',
        },
        {
          label: 'ĐỊA ĐIỂM LÀM VIỆC',
          field: 'addressWorking',

        },
        {
          label: 'VỊ TRÍ CÔNG VIỆC',
          field: 'position',

        },
        {
          label: 'CHỨC DANH CÔNG VIỆC',
          field: 'title',
        },
        {
          label: 'NGÀY BẮT ĐẦU',
          field: 'dateStart',
        },
        {
          label: 'NGÀY KẾT THÚC',
          field: 'dateEnd',
        },
        {
          label: 'CHỨC NĂNG',
          width: '200px',
          tdClass: 'actiontd',
          thClass: 'actionth',
          field: 'cn',
        },
      ],
      urlQuery: {
        workerId: this.$route.params.id,
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      totalRecord: 0,
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      showBtnMultiDelete: false,
    }
  },
  // ===========================CREATED====================
  created() {
    if (this.$route.params.id) {
      this.fetchDataWorkerProgress(this.urlQuery)
    }
  },
  // =============================METHOD======================
  methods: {

    // Lấy danh sách quá trình làm việc
    async fetchDataWorkerProgress(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_WORKER_PROGRESS, {
        params: urlQuery,
      })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataWorkerProgress(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataWorkerProgress(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataWorkerProgress(this.urlQuery)
      }
    },

    // Xóa giấp phép lao động
    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_WORKER_PROGRESS, { ids: this.deleteIds, workerId: this.$route.params.id }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataWorkerProgress(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Mở modal tạo mới quá trình làm việc
    openModalCreateProgress() {
      this.$bvModal.show(this.modalCreateProgress)
    },

    // Tạo mới quá trình làm việc
    async handleCreateProgress(val, validate) {
      validate.validate().then(async success => {
        if (success) {
          const data = {
            ...val,
          }
          this.$showAllPageLoading()
          await axiosApiInstance.post(ConstantsApi.CREATE_WORKER_PROGRESS, data).then(res => {
            if (res.status === 200) {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('thêm thành công', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
              this.$bvModal.hide(this.modalCreateProgress)
              this.fetchDataWorkerProgress(this.urlQuery)
            }
          }).catch(err => {
            this.$hideAllPageLoading()
            this.$root.$bvToast.toast(`${err.response.data?.errors[0]}`, {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          })
        }
      })
    },

  },
}
</script>

<style lang="scss">
#filter-custom{
  .header{
    font-size: 18px;
    color: #181F28;
  }
}
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
.bg-color{
  color : #fff;
  text-align: center;
}
.inline{
  display: inline-block;
}
.custtom-header{
    padding-right: 20px;
}

.style-code {
  span {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
    padding: 1px 9px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #227ff4;
  }
}
.type-blue{
display: inline-block;
     background-color: #28C76F1F;
    border-radius: 4px;
  padding:1px 9px;
  color: #28C76F;
}
.type-orange{
display: inline-block;
     background-color: #FF9F431F;
    border-radius: 4px;
  padding:1px 9px;
  color: #FF9F43;
}
.css-bottom{
padding-top: 70px;
}
</style>
