<template>
  <div
    id="addBusiness"
  >
    <my-tabs
      :listTabs="listTabs"
      defaultActive="user-edit-tab-account"
      typeCss="active-full"
      @setActive="setActive"
    >
    </my-tabs>
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="rule">
        <general-information
          v-if="isRendered('general')"
          id="general"
          :dataGeneral="dataGeneral"
          class="tab-pane"
          :class="{'active':activeTab==='general'}"
          @checkId="checkId"
        />
        <worker-information
          v-if="isRendered('worker')"
          id="worker"
          ref="WorkerInformation"
          class="tab-pane"
          :userTypeId="userTypeId"
          :class="{'active':activeTab==='worker'}"
        />
        <work
          v-if="isRendered('work')"
          id="work"
          class="tab-pane"
          :class="{'active':activeTab==='work'}"
        />
        <training
          v-if="isRendered('training')"
          id="training"
          ref="trained"
          class="tab-pane"
          :class="{'active':activeTab==='training'}"
        />
        <over-time-work
          v-if="isRendered('overTime')"
          id="overTime"
          ref="overTime"
          class="tab-pane"
          :class="{'active':activeTab==='overTime'}"
        />
      </validation-observer>
      <div class="add-business__button">
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="save()"
          >
            Lưu lại
          </b-button>
        </div>
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="returnList"
          >
            Quay về
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import {
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters, mapState } from 'vuex'
import GeneralInformation from './tag-list/GeneralInformation.vue'
import WorkerInformation from './tag-list/WorkerInformation.vue'
import Work from './tag-list/Work.vue'
import Training from './tag-list/Training.vue'
import OverTimeWork from './tag-list/OverTimeWork.vue'
import MyTabs from '@/components/tabs/MyTabs.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    GeneralInformation,
    BButton,
    MyTabs,
    WorkerInformation,
    ValidationProvider,
    ValidationObserver,
    Work,
    Training,
    OverTimeWork,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      checkCodeData: '',
      checkEmailData: '',
      activeTab: null,
      listTabs: [
        {
          key: 'general',
          title: 'Thông tin chung',
          icon: 'BriefcaseIcon',
          isRendered: false,
        },
        {
          key: 'worker',
          title: 'Thông tin người lao động',
          icon: 'UsersIcon',
          isDisabled: false,
          isRendered: false,
        },
        {
          key: 'training',
          title: 'Quá trình đào tạo',
          icon: 'BookOpenIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'work',
          title: 'Quá trình làm việc',
          icon: 'BriefcaseIcon',
          isDisabled: true,
          isRendered: false,
        },
        {
          key: 'overTime',
          title: 'Làm thêm giờ',
          icon: 'ClockIcon',
          isDisabled: true,
          isRendered: false,
        },
      ],
      userTypeId: null,
      dataGeneral: {
        avatar: null,
        gender: true,
        code: null,
        name: null,
        countryId: null,
        birthDayString: null,
        socialInsuranceCode: null,
        identityCardNumber: null,
        locationIssued: null,
        dateExpiredString: null,
        phoneNumber: null,
        address: null,
        email: null,
        academicLevel: 'University',
        typeOfProfessionId: null,
        workingFormId: null,
        provinceId: 0,
        districtId: 0,
        wardId: 0,
      },
    }
  },
  computed: {
    ...mapState('worker', ['dataEdit']),
  },
  watch: {
    $route(to, from) {
      if (to && to.params.type) {
        this.activeTab = to.params.type
        const tab = this.listTabs.find(x => x.key === this.activeTab)
        if (tab) tab.isRendered = true
      }
    },

    dataEdit(val) {
      if (val) {
        this.dataGeneral = {
          birthDayString: this.dataEdit.birthDayString,
          avatar: this.dataEdit.avatar,
          gender: this.dataEdit.gender,
          code: this.dataEdit.code,
          name: this.dataEdit.name,
          countryId: this.dataEdit.countryId,
          socialInsuranceCode: this.dataEdit.socialInsuranceCode,
          identityCardNumber: this.dataEdit.identityCardNumber,
          locationIssued: this.dataEdit.locationIssued,
          dateExpiredString: this.dataEdit.dateExpiredString,
          phoneNumber: this.dataEdit.phoneNumber,
          address: this.dataEdit.address,
          email: this.dataEdit.email,
          academicLevel: this.dataEdit.academicLevel,
          bussinessId: this.dataEdit.bussinessId,
          workingFormId: this.dataEdit.workingFormId,
          typeOfProfessionId: this.dataEdit.typeOfProfessionId,
          provinceId: this.dataEdit.provinceId,
          districtId: this.dataEdit.districtId,
          wardId: this.dataEdit.wardId,
        }
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.listTabs[2].isDisabled = false
      this.listTabs[3].isDisabled = false
      this.listTabs[4].isDisabled = false
      this.listTabs[1].isRendered = true
      this.listTabs[2].isRendered = true
      this.fetchdataIdST(this.$route.params.id)
    }
    if (this.activeTab === null) {
      this.activeTab = 'general'
      this.listTabs[0].isRendered = true
      this.listTabs[1].isRendered = true
    }
  },
  methods: {
    ...mapActions('worker', ['fetchdataIdST']),
    // *****APi*****//

    checkId(val) {
      if (val !== null) {
        this.userTypeId = val
      } else {
        this.userTypeId = 0
      }
    },

    isRendered(key) {
      const tab = this.listTabs.find(x => x.key === key)
      if (tab) return tab.isRendered
      return false
    },
    // thay đổi active tab
    setActive(activeTab) {
      this.activeTab = activeTab
      const tab = this.listTabs.find(x => x.key === activeTab)
      if (tab) tab.isRendered = true
    },
    // Phần sửa/
    // eslint-disable-next-line consistent-return
    async editST(dataAdd) {
      try {
        this.$showAllPageLoading()
        const ref = await axiosApiInstance.put(ConstantsApi.EDIT_ST, dataAdd)
        return ref
      } catch (e) {
        return e.response
      }
    },

    // ===Ấn nút lưu lại ===//
    async save() {
      this.$refs.rule.validate().then(async success => {
        if (success) {
          if (this.$route.params.id) {
            const data = {
              ...this.dataGeneral,
              ...this.$refs.WorkerInformation.dataWorker,
              ...this.$refs.trained.dataTraining,
              id: this.$route.params.id,
              salaryRatio: parseInt(this.$refs.WorkerInformation.dataWorker.salaryRatio, 10),
              salary: parseInt(this.$refs.WorkerInformation.dataWorker.salary, 10),
              extras: parseInt(this.$refs.WorkerInformation.dataWorker.extras, 10),
              overtimeAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.overtimeAllowance, 10),
              positionAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.positionAllowance, 10),
              salaryAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.salaryAllowance, 10),
              seniorityAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.seniorityAllowance, 10),
            }
            const ref = await this.editST(data)
            if (ref.status === 200) {
              this.$hideAllPageLoading()
              this.$router.push({ name: 'manage-workers' })
              this.$root.$bvToast.toast('Sửa thành công!', {
                title: 'Thông báo',
                variant: 'success',
                toaster: this.$toastPosition,
                solid: true,
              })
            } else {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast('Sửa không thành công!', {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            }
          } else {
            const data = {
              ...this.dataGeneral,
              ...this.$refs.WorkerInformation.dataWorker,
              salaryRatio: parseInt(this.$refs.WorkerInformation.dataWorker.salaryRatio, 10),
              salary: parseInt(this.$refs.WorkerInformation.dataWorker.salary, 10),
              extras: parseInt(this.$refs.WorkerInformation.dataWorker.extras, 10),
              overtimeAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.overtimeAllowance, 10),
              positionAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.positionAllowance, 10),
              salaryAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.salaryAllowance, 10),
              seniorityAllowance: parseInt(this.$refs.WorkerInformation.dataWorker.seniorityAllowance, 10),
            }
            this.$showAllPageLoading()
            await axiosApiInstance.post(ConstantsApi.ADD, data).then(res => {
              if (res.status === 200) {
                this.$hideAllPageLoading()
                this.$root.$bvToast.toast('Thêm thành công!', {
                  title: 'Thông báo',
                  variant: 'success',
                  toaster: this.$toastPosition,
                  solid: true,
                })
              }
              this.$router.push({ name: 'manage-workers' })
            }).catch(e => {
              this.$hideAllPageLoading()
              this.$root.$bvToast.toast(e.response.data?.errors[0], {
                title: 'Thông báo',
                variant: 'danger',
                toaster: this.$toastPosition,
                solid: true,
              })
            })
          }
        } else {
          let { errors } = this.$refs.rule
          errors = Object.values(errors).filter(item => item[0]?.includes('là bắt buộc'))
          errors = errors.map((item, index) => {
            if (index !== 0) {
              return ` ${item[0].slice(0, item[0].length - 12)}`
            }
            return item[0].slice(0, item[0].length - 12)
          })
          this.$root.$bvToast.toast(`Vui lòng điền thông tin ${errors}`, {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    returnList() {
      this.$router.push({ name: 'manage-workers' })
    },
  },
}
</script>

<style lang="scss">
#addBusiness {
  .add-business__button {
    padding-top: 40px;
    display: flex;
    &__item {
      margin-right: 20px;
    }
  }
}
</style>
