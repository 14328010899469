<!-- Hướng dẫn
   truyền từ cha xuống con 2 props :icon và :title
-->

<template>
  <div class="infomation-and-adress__title">
    <div class="infomation-and-adress__title__icon">
      <feather-icon
        :icon="icon"
        for="file"
        class="icon_info"
      />
    </div>
    <div>
      <span class="infomation-and-adress__title__name-title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'InfoIcon',

    },
    title: {
      type: String,
      default: 'Thông tin',
    },
  },

}
</script>

<style lang="scss">
.infomation-and-adress__title{
     padding: 20px 0px;
     display: flex;
     align-items: center;
     &__icon{
         margin-right: 10px;
     }
    &__name-title{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 21px !important;
        line-height: 26px;
        color: #2E3A4A;
        h1{
        margin: 0px;
        }
    }
  }
  .icon_info{
  width: 20px;
  height: 20px;
  }

</style>
