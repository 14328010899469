var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"classifyItem"}},[_c('div',{staticClass:"p-2"},[_c('h3',{staticClass:"header"},[_vm._v(" Bộ lọc ")]),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Năm")]),_c('v-select',{attrs:{"reduce":function (label) { return label.year; },"label":"year","options":_vm.dataFilterYear || [],"placeholder":'Năm'},on:{"input":_vm.filterYear},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1)],1)],1)],1),_c('div',[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm làm thêm giờ',"arrayExcel":[],"showBtnMultiDelete":_vm.showBtnMultiDelete,"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideSearch":false},on:{"clickAdd":_vm.showModalCreate,"clickDelete":_vm.deleteItems}}),_c('vue-good-table',{ref:"user-table",attrs:{"style-class":"vgt-table","columns":_vm.columns,"rows":_vm.dataTable || [],"select-options":{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'dateOverTime')?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatDateToDDMM")(props.row.dateOverTime)))])]):(props.column.field === 'actionFunction')?_c('span',[_c('feather-icon',{staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord,"currentPage":_vm.urlQuery.pageNumber},on:{"pageClick":_vm.handlePageClick}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}}),_c('modal-add-service',{attrs:{"id":_vm.modalAddService},on:{"handleCreateOverTime":_vm.handleCreateOverTime}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }