<template>
  <div
    id="classifyItem"
  >

    <div class="p-2">
      <h3 class="header">
        Bộ lọc
      </h3>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Năm</label>
            <v-select
              :reduce="label => label.year"
              label="year"
              :options="dataFilterYear || []"
              :placeholder="'Năm'"
              @input="filterYear"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div>
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm làm thêm giờ'"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        :hideSearch="false"
        @clickAdd="showModalCreate"
        @clickDelete="deleteItems"
      />

      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <!-- Slot: Table Row -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'dateOverTime'">
            <span>{{ props.row.dateOverTime | formatDateToDDMM }}</span>
          </span>
          <!-- Column:Chức năng -->
          <span v-else-if="props.column.field === 'actionFunction'">
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(props.row.id)"
            />
          </span>
        </template>
      </vue-good-table>

      <!-- pagination -->
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- Cập nhật loại báo cáo -->
      <modal-add-service
        :id="modalAddService"
        @handleCreateOverTime="handleCreateOverTime"
      />
    </div>
  </div>
</template>

<script>
import { formatDateToDDMM } from '@core/utils/filter'
// import { mapActions, mapGetters } from 'vuex'
import {
  BBadge,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import ModalAddService from '../components/ModalAddService.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import DataFilter from '../../constants/DataFilter'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    BBadge,
    ModalAddService,
    ConfirmModal,
    vSelect,
    BRow,
    BCol,
    BFormGroup,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      confirmModalId: 'confirm-modal',
      columns: [
        {
          label: 'NGÀY LÀM THÊM',
          field: 'dateOverTime',
          sortable: false,
        },
        {
          label: 'SỐ GIỜ LÀM THÊM',
          field: 'hour',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'actionFunction',
          sortable: false,
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
      currentPage: 0,
      modalContent: '',
      showBtnMultiDelete: false,
      totalRecord: 0,
      urlQuery: {
        workerId: this.$route.params.id,
        year: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      dataFilterYear: DataFilter.dataFilterYear,
      modalAddService: 'confirm-add-service',
    }
  },
  created() {
    this.fetchOvertimeWorking(this.urlQuery)
  },
  methods: {

    async fetchOvertimeWorking(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OVERTIME_WORKING, {
        params: urlQuery,
      })

      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lọc theo năm
    filterYear(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = val
        this.fetchOvertimeWorking(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.year = ''
        this.fetchOvertimeWorking(this.urlQuery)
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchOvertimeWorking(this.urlQuery)
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        workerId: this.$route.params.id,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_OVERTIME_WORKING, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchOvertimeWorking(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // click show modal thêm
    showModalCreate() {
      this.$bvModal.show(this.modalAddService)
    },
    async handleCreateOverTime(val) {
      const payload = {
        ...val,
        hour: parseInt(val.hour, 10),
        workerId: this.$route.params.id,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_OVERTIME_WORKING, payload).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalAddService)
          this.fetchOvertimeWorking(this.urlQuery)
        }
      }).catch(err => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(err.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

#classifyItem {
  .edit-report {
    cursor: pointer;
  }

  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }

  .button-icon {
    .btn-component {
      width: 38px;
      height: 38px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 11px;
    }
  }

  .button-icon-custom {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
  }

  .search-input {
    width: 400px;
  }

  .button-import {
    .input-file {
      display: none;
    }
  }

  .btn-icon-file {
    width: 38px;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 11px;
    label {
      margin-bottom: unset !important;
    }
  }
}
</style>
